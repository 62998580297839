import axios from "axios";

// Dynamically determine the API base URL
const API_URL = `${window.location.protocol}//${window.location.hostname}:8000/api`;

// Create an Axios instance with default headers
const axiosInstance = axios.create({
    baseURL: API_URL,
    headers: {
        Authorization: `Bearer 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`, // Replace with your OpenAI API Key
        "OpenAI-Beta": "assistants=v2",
        "Content-Type": "application/json",
    },
});

// Export your API functions
export const createNewThread = async () => {
    try {
        const response = await axiosInstance.post("/new");
        return response.data;
    } catch (error) {
        console.error("Error creating new thread:", error);
        throw error;
    }
};

export const fetchThread = async (threadId) => {
    try {
        const response = await axiosInstance.get(`/threads/${threadId}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching thread:", error);
        throw error;
    }
};

export const fetchRun = async (threadId, runId) => {
    try {
        const response = await axiosInstance.get(`/threads/${threadId}/runs/${runId}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching run:", error);
        throw error;
    }
};

export const postMessage = async (threadId, message) => {
    try {
        const response = await axiosInstance.post(`/threads/${threadId}`, { content: message });
        return response.data;
    } catch (error) {
        console.error("Error posting message:", error);
        throw error;
    }
};

export const postToolResponse = async (threadId, runId, toolResponses) => {
    try {
        const response = await axiosInstance.post(`/threads/${threadId}/runs/${runId}/tool`, toolResponses);
        return response.data;
    } catch (error) {
        console.error("Error posting tool response:", error);
        throw error;
    }
};
